@import 'variables';
@import 'functions';
@import 'mixin';
@import 'type';
@import 'icon-font';
.reg_auth_row {
  width: 100%;
  height: rem(60);
  background-image: linear-gradient(92.5deg, #425AF0 0.41%, #51B1FA 100%);
  -webkit-background-image: linear-gradient(92.5deg, #425AF0 0.41%, #51B1FA 100%);
  @include prefix_val_inn((
          display: flex,
  ), webkit);
  @include prefix((
          align-items: center,
          justify-content: space-between
  ), webkit);
  font-size: rem(15);
  padding: rem(5) rem(40);
  a {
    display: block;
    height: 100%;
    &.auth_link {
      color: $white;
      text-decoration: none;
      padding-left: rem(35);
      position: relative;
      &:before {
        content: '';
        font-family: 'sellerboard', sans-serif;
        position: absolute;
        left: 0;
        top: 50%;
        font-size: rem(20);
        color: $white;
        transform: translateY(-50%);
      }
      &.register__link:before {
        content: '\ea02';
      }
      &.login__link:before {
        content: '\e992';
      }
    }
  }
}
.page_connect_head {
  background-color: $white;
  padding-top: rem(50);
  padding-bottom: rem(60);
  .logo_head {
    position: relative;
    @include prefix_val_inn((
            display: flex,
    ), webkit);
    @include prefix((
            align-items: center,
            justify-content: center
    ), webkit);
    padding-bottom: rem(20);
    .connect_exit {
      position: absolute;
      right: 0;
      font-size: rem(18);
      color: $dark_grey;
      display: grid;
      grid-auto-flow: column;
      grid-gap: 5px;
      align-items: center;
      &:hover {
        text-decoration: none;
        color: $black;
      }
    }
  }
  .connection_title_section {
    padding-top: rem(10);
    padding-bottom: rem(24px);

    h1 {
      font-size: rem(36);
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      color: $black;
    }

    .connection_title_section_h1-small {
      font-size: rem(24px);
      max-width: rem(886px);
      margin-left: auto;
      margin-right: auto;
    }

    .subtitle {
      font-size: rem(24);
      color: $dark_grey;
      padding-top: rem(16);
    }
  }

  @media (min-width: 768px) {
    .connection_title_section {
      padding-bottom: rem(50px);

      .connection_title_section_h1-small {
        font-size: rem(32px);
      }
    }
  }
}
.container_inner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: rem(25);
  padding-top: rem(20);
  .inner_item {
    min-width: 0;
    @include prefix_val_inn((
            display: flex,
    ), webkit);
    @include prefix((
            flex-direction: column
    ), webkit);
    .inner_text {
      flex-grow: 1;
      padding-bottom: rem(13);
      font-size: rem(20);
      line-height: rem(27.24);
    }
    .inner_footer {
      text-transform: uppercase;
      font-weight: 600;
      font-size: rem(16);
      line-height: rem(21.79);
      @include prefix_val_inn((
              display: flex,
      ), webkit);
      @include prefix((
              align-item: center
      ), webkit);
    }
  }
}
.auth__wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
  @include prefix_val_inn((
          display: flex,
  ), webkit);
  @include prefix((
          justify-content: center
  ), webkit);
  background-color: $light_grey;
  .form__outer {
    width: 100%;
    max-width: rem(380);
    overflow-y: auto;
    max-height: 100%;
    padding: rem(25) 0;
    .feedback_block {
      padding: rem(29) 0 0;
      &.long_padding {
        padding: rem(30px) 0 0;
      }
    }
    &.no-flex {
      max-width: rem(408);
    }
  }

  .auth__block {
    width: 100%;
    border: 1px solid #F0F0F0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
    background-color: $white;
    border-radius: 8px;
    padding: rem(25) rem(40);
    .form-title {
      padding-bottom: rem(7);
    }
    h6 {
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      font-size: rem(15);
      color: $black;
      margin-bottom: rem(15);
    }
    .sub_title, .have_user {
      font-size: rem(15);
      line-height: 1.36;
      color: $black;
      padding-bottom: rem(15);
    }
    .have_user {
      padding-left: rem(10);
      padding-right: rem(10);
      a {
        display: block;
      }
    }
    .form__group {
      margin-bottom: rem(16);
      position: relative;
      &:before {
        content: '';
        width: 20px;
        height: auto;
        position: absolute;
        left: 0;
        font-size: rem(20);
        color: $dark_grey;
      }
      &.email__input:before {
        background: url("../img/mail-icon.svg") no-repeat center center / contain;
        height: 14px;
        top: 15px;
      }
      &.password__input:before {
        background: url("../img/lock-icon.svg") no-repeat center center / contain;
        height: 19px;
        top: 12px;
      }
      .form-control {
        height: rem(50);
        background-color: $white;
        font-size: rem(16px);
        color: $black;
        border-color: #DDDDDD;
        border-width: 0 0 1px;
        border-radius: 0;
        padding: rem(10) 0 rem(16) rem(35);
        &:focus, &:hover {
          outline: none;
          box-shadow: none;
          border-color: #DDDDDD;
        }

        &.form_error {
          border-color: $danger;
          &:focus, &:hover {
            border-color: $danger;
          }
        }
      }
      .form-control:not(:placeholder-shown) {
        border-color: $blue;
      }
      .form-control.form_error:not(:placeholder-shown) {
        border-color: $danger;
      }
    }
    .check_parent {
      padding-top: rem(9);
      > div ~ div {
        margin-top: rem(7);
      }
    }
    .login_button_outer {
      width: 100%;
      padding-top: rem(14);
      button.submit_button {
        height: rem(50);
        @include prefix_val_inn((
                display: flex,
        ), webkit);
        @include prefix((
                align-items: center,
                justify-content: center
        ), webkit);
        background-color: $blue;
        color: $white;
        font-size: rem(15);
        font-weight: 600;
        border: 0 none;
        box-shadow: 0 4px 14px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        width: 100%;
        transition: background-color .3s ease-in-out 0s;
        &:hover, &:focus {
          background-color: $blue_hover;
          outline: none;
        }

        .spinner-border {
          display: none;
          width: 1em;
          height: 1em;
          margin-left: rem(16px);
          border-width: 2px;
        }

        &.opacity-25 .spinner-border {
          display: inline-block;
        }
      }
    }
    a {
      color: $blue;
      text-decoration: none;
      &:hover, &:active {
        text-decoration: underline;
      }
    }
  }
  .confirm__block {
    background: $white;
    border: 1px solid #F0F0F0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: rem(8);
    overflow: hidden;
    .confirm_title {
      text-align: center;
      background-color: $success;
      color: $white;
      font-size: rem(15);
      line-height: rem(21.93);
      padding: rem(20) rem(50);
    }
    .confirm_description {
      position: relative;
      background: $white;
      padding: rem(20) rem(30) rem(20) rem(80);
      font-size: rem(15);
      color: $title-color;
      line-height: rem(22.53);
      &:before {
        content: "\e92d";
        font-family: 'sellerboard', sans-serif;
        font-size: rem(29);
        color: $dark;
        position: absolute;
        left: rem(30);
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .service__links {
    @include prefix_val_inn((
            display: flex,
    ), webkit);
    @include prefix((
            align-items: center,
            justify-content: space-between
    ), webkit);
    padding: rem(15) 0 0;
    .input__checkbox-label {
      font-size: rem(13);
      padding-top: 1px;
      &::before {
        top: rem(-1);
      }
      &::after {
        top: rem(3px);
      }
    }
  }
  .bottom_link {
    display: grid;
    grid-auto-flow: column;
    @include prefix((
            align-items: center,
            justify-content: center
    ), webkit);
    grid-gap: rem(10);
    padding-top: rem(12);
  }
}


.have_user p:last-child {
  margin: 0;
}


input[type="password"]:not(:placeholder-shown) {
  font-family:'text-security-disc', sans-serif !important;
  color: $black!important;
  font-size: rem(29px)!important;
  letter-spacing: rem(2px);
}
.input_block {
  position: relative;
  span.form__error {
    display: block;
    position: absolute;
    left: 0;
    bottom: rem(-19);
    font-size: 12px;
    color: $danger;
  }
}
.check_parent .input_block {
  margin-bottom: 15px;
  span.form__error {
    bottom: rem(-16);
  }
}
.auth-block-inner .info_block {
  border: 1px solid #f0f0f0;
  background-color: $white;
  border-radius: 8px;
  display: grid;
  grid-template-columns: rem(54) 1fr;
  //min-height: rem(121);
  overflow: hidden;
  .info_block_icon {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #f0f0f0;
    span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: $white;
      font-size: rem(28);
    }
    &:after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      top: 50%;
      right: -8px;
      transform: translateY(-50%);
      border-width: rem(15) 0 rem(15) rem(15);
      border-left-color: #f0f0f0;
    }
  }
  .info_block_content {
    position: relative;
    padding: rem(12px);
    .success_link, .close_info {
      position: absolute;
      font-size: rem(12);
      top: rem(12);
      right: rem(13);
      color: $dark_grey;
      line-height: 1;
      &:hover {
        text-decoration: none;
      }
    }
    .close_info {
      right: rem(10);
      top: rem(10);
      font-size: rem(11);
      cursor: pointer;
    }
    .block_content_text {
      font-size: rem(15);
      color: #232323;
      line-height: rem(22.53);
      padding-top: rem(2);
       a {
         display: block;
       }
    }
  }
  &.success_block {
    border: 1px solid $success;
    .info_block_icon {
      background-color: $success;
      &:after {
        border-left-color: $success;
      }
    }
  }
  &.warning_block {
    border: 1px solid $warning;
    .info_block_icon {
      background-color: $warning;
      span {
        font-size: rem(36);
      }
      &:after {
        border-left-color: $warning;
      }
    }
  }
  &.log__error_block {
    grid-template-columns: rem(54) 1fr;
    margin-bottom: rem(20);
    display: none;
    .info_block_content {
      padding: rem(12);
      .block_content_text a {
        padding-left: 0;
      }
    }
  }
}
.check_parent .input_block:last-of-type {
  margin-bottom: rem(5);
}
aside.aside {
  height: 100vh;
  //display: inline-block;
  flex: 0 0 rem(100);
  position: fixed;
  left: 0;
  top: 0;
  max-width: rem(100);
  //overflow-y: auto;
  width: rem(100);
  background: $white;

  @media (min-width: 992px) {
    box-shadow: 4px 0 16px 2px rgba(0, 0, 0, 0.03);
  }
}
aside .logo_block {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  height: rem(50);
  border-bottom: 1px solid $border_color;
}
.auth_row {
  height: rem(50);
  position: fixed;
  top: 0;
  left: rem(100);
  right: 0;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  background-color: $white;
  padding: 0 rem(9px);
  z-index: 5;
  transition: transform .2s ease-in-out 0s;
  &.active {
    transform: translateX(100px);
  }
}

/* mouse, touch pad */
@media (hover: hover) and (pointer: fine) {
  .modal-open .auth_row {
    right: 17px;
  }
}

.auth_row .auth_row_inner {
  height: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  // border-bottom: 1px solid $border_color;
  width: 100%;

  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: rem(20px);
    width: calc(100% - rem(40px));
    height: 1px;
    background: $border_color;
  }

  .tf_mobile__switcher {
    display: none;
    position: relative;
    padding: 0;
    margin-top: 0;
    z-index: 999;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: rem(38px);
    height: rem(38px);
    color: $black;

    .feather {
      width: rem(16px);
      height: rem(16px);

      line {
        position: relative;
        transform-origin: center;

        &:first-child {
          transition: top .3s .3s, transform .3s 0s;
        }

        &:nth-child(2) {
          opacity: 1;
          transition: opacity .3s;
        }

        &:last-child {
          transition: top .3s .3s, transform .3s 0s;
        }
      }
    }

    &.active {
      .feather {
        line {
          &:first-child {
            transform: rotate(-45deg) translate(0, 0);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:last-child {
            transform: rotate(45deg) translate(0, rem(-6px));
          }
        }
      }
    }
  }
  .tf_mobile__switcher span {
    display: block;
    width: rem(13px);
    height: rem(2px);
    margin-bottom: rem(2px);
    border-radius: rem(1px);
    background: rgba(0, 0, 0, 1); }
  .tf_mobile__switcher span:first-child {
    position: relative;
    top: 0;
    transition: top .3s .3s, transform .3s 0s; }
  .tf_mobile__switcher span:nth-child(2) {
    opacity: 1;
    transition: opacity .3s; }
  .tf_mobile__switcher span:last-child {
    margin-bottom: 0;
    position: relative;
    top: 0;
    transition: top .3s .3s, transform .3s 0s; }
  .tf_mobile__switcher.active span {
    background: rgba(0,0,0,1); }
  .tf_mobile__switcher.active span:first-child {
    top: rem(4px);
    transform: rotate(-45deg);
    transition: top .3s 0s, transform .3s .3s; }
  .tf_mobile__switcher.active span:nth-child(2) {
    opacity: 0; }
  .tf_mobile__switcher.active span:last-child {
    top: rem(-4px);
    transform: rotate(45deg);
    transition: top .3s 0s, transform .3s .3s;
  }
}
.auth_row_inner_nav {
  @include prefix_val_inn((
      display: flex,
  ), webkit);
  @include prefix((
      align-items: center,
  ), webkit);
  margin: 0;
  padding: 0;
  >li ~ li {
    margin-left: rem(8px);
  }
  li a {
    color: $dark_grey;
    text-decoration: none;
  }
}

@media (min-width: 576px) {
  .auth_row_inner_nav > li ~ li {
    margin-left: rem(24px);
  }
}

.page_title_outer, .auth_title_block {
  display: flex;
  align-items: center;
  gap: rem(16px);
}

.page-beta {
  font-size: rem(12px);
  color: #999;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: rem(8px);
}

.page-beta__inner {
  display: none;

  @media (min-width: 992px) {
    display: block;
  }
}

.test-label__ico {
  margin-right: rem(4px);
}

.page_title {
  font-size: 16px;
  font-weight: 700;
  color: $black;
  margin: 0;
}
.inner_nav_alert a span[class^="fm-"], .inner_nav_alert a span[class*=" fm-"],
.inner_nav_clock a span[class^="fm-"], .inner_nav_clock a span[class*=" fm-"] {
  font-size: rem(17);
  color: $dark_grey;
}
.inner_nav_clock {
  display: block;
  position: relative;
  .inner_nav_clock_tooltip {
    position: absolute;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    width: 160px;
    pointer-events: none;
    text-shadow: none;
    text-transform: none;
    transition: all 1.5s ease;
    word-wrap: break-word;
    box-shadow: 0 0 2px 0 rgba(0,0,0,.45);
    background: #efefef;
    color: #000;
    padding: 2px 6px 3px 6px;
    border-radius: 2px;
    margin-top: 7px;
    top: 105%;
    right: 0;
    display: none;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0,0,0);
    line-height: 1.2;
    z-index: 10;
  }
  &:hover .inner_nav_clock_tooltip {
    display: block;
    opacity: 1;
    overflow: visible;
    visibility: visible;
  }
}
.user_auth_dropdown {
  position: relative;
  .user_block {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    cursor: pointer;
    .user_thumb {
      position: relative;
      height: rem(38);
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      border-radius: 50%;
      background-color: transparent;
      width: rem(38);
      color: $black;
      span {
        font-size: rem(16px);
        line-height: .8;
      }

      .feather {
        width: rem(16px);
        height: rem(16px);
        flex-shrink: 0;
      }

    }

    .user_thumb_ozon,
    .user_thumb_wb {
      &::after {
        content: "";
        width: rem(14px);
        height: rem(14px);
        position: absolute;
        right: rem(-2px);
        top: rem(-2px);
      }
    }

    .user_thumb_ozon {
      &::after {
        background: url('/img/ozon-ico.svg') no-repeat center center / contain;
      }
    }

    .user_thumb_wb {
      &::after {
        background: url('/img/wb-ico.svg') no-repeat center center / contain;
      }
    }
  }
  .user_account_status {
    position: relative;
    font-size: rem(13);
    color: $dark_grey;
    font-weight: 600;
    margin-left: rem(10);
    padding-right: 16px;
    max-width: rem(160);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
      div {
          overflow: hidden;
      }
  }
  .user_account_status:after {
    content: '\e930';
    font-family: 'sellerboard', sans-serif !important;
    position: absolute;
    right: 0;
    top: rem(1);
    font-size: rem(14);
  }
  .user_auth_nav {
    display: none;
    position: absolute;
    right: 0;
    box-shadow: 0 4px 20px rgba(0,0,0,.2);
    backdrop-filter: blur(6px);
    background-color: rgba(255,255,255,.9);
    border-radius: 8px;
    border: none;
    top: calc(100% + 7px);
    min-width: 200px;
    z-index: 5;
  }
  &.active .user_auth_nav {
    display: block;
  }
  .user_auth_nav_list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: rem(8);
    overflow: hidden;
    li {
      position: relative;
    }
    li:not(:last-of-type):before {
      content: '';
      position: absolute;
      left: rem(20);
      bottom: 0;
      right: rem(20);
      height: 1px;
      background-color: rgba(0,0,0,.1);
      z-index: 2;
    }
  }
  li.mobile_only {
    display: none;
  }
  .user_auth_nav_item {
    display: block;
    padding: 0;
    margin: 0;
  }
  .user_auth_nav_item_link {
    @include prefix_val_inn((
        display: flex,
    ), webkit);
    @include prefix((
        align-items: stretch,
    ), webkit);
    text-decoration: none;
    color: $dark;
  }
  .user_auth_nav_item_link.have_alert {
    span.alert_icon {
      color: $danger;
    }
  }
  .user_auth_nav_item_link > span {
    display: block;
    position: relative;
    color: rgba(0,0,0,.6);
    width: 100%;
    overflow: hidden;
    font-size: rem(13);
    text-overflow: ellipsis;
    padding: rem(18) rem(16);
  }
  .user_auth_nav_item_link > .nav_item_link_grid {
    display: grid;
    @include prefix((
            align-items: center
    ), webkit);
    grid-template-columns: auto 1fr;
    grid-gap: rem(20);
    position: relative;
    color: rgba(0,0,0,.6);
    width: 100%;
    overflow: hidden;
    font-size: rem(13);
    text-overflow: ellipsis;
    padding: rem(18) rem(16);
  }
  .user_auth_nav_item.active .user_auth_nav_item_link > span,
  .user_auth_nav_item_link.active > .nav_item_link_grid,
  .user_auth_nav_item:hover  .user_auth_nav_item_link> span,
  .user_auth_nav_item_link:hover> .nav_item_link_grid {
    background: #f5f5f5;
    color: $black;
  }

    .user_auth_nav_item {
        .user_auth_nav_item_link span:before {
            content: '';
            font-family: 'sellerboard', sans-serif !important;
            position: absolute;
            right: rem(16);
            top: 50%;
            transform: translateY(-50%);
        }

        &.account_item .user_auth_nav_item_link span:before {
            content: '\e92b';
            color: $primary-200;
            font-size: rem(16);
        }

        &.logout .user_auth_nav_item_link span:before {
            content: '\e9c2';
            color: $dark;
            font-size: rem(16);
        }

        &.user_auth_nav_item_ico {
            .user_auth_nav_item_link span:before {
                content: '\e9be';
                font-family: 'sellerboard', sans-serif !important;
                position: absolute;
                right: 1rem;
                top: 50%;
                transform: translateY(-50%);
                font-size: rem(16px);
            }
        }

        &.user_auth_nav_item_ozon {
          .user_auth_nav_item_link span:before {
            content: "";
            background: url('/img/ozon-ico.svg') no-repeat center center / contain;
            width: rem(18px);
            height: rem(18px);
          }
        }

        &.user_auth_nav_item_wb {
          .user_auth_nav_item_link span:before {
            content: "";
            background: url('/img/wb-ico.svg') no-repeat center center / contain;
            width: rem(18px);
            height: rem(18px);
          }
        }
    }

}
.reg_auth_row .user_auth_dropdown .user_account_status {
  color: $white;
  &:after {
    color: $white;
  }
}
aside.aside {
  z-index: 6;
  .main_nav {
    height: calc(100vh - 50px);
    // overflow-y: auto;
    // overflow-x: hidden;

    .main_nav_item__inner {
      display: none;
      position: absolute;
      top: 0;
      left: calc(100% - rem(4px));
      z-index: 2;
      padding: rem(12px) rem(8px);
      background-color: $white;
      box-shadow: 0 8px 24px 2px rgba(0, 0, 0, 0.15);
      border-radius: rem(10px);
      min-width: rem(240px);

      &.active {
        display: block;
      }

      .main_link {
        height: auto;
        padding: rem(12px);

        &::after {
          height: rem(24px);
        }

        > div {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: rem(8px);
        }

        span:not(.title) {
          font-size: rem(16px);

          svg {
            width: rem(16px);
            height: rem(16px);
          }
        }
      }
    }

    .main_nav_item {
      position: relative;
      display: block;
      width: 100%;
    }

    &::-webkit-scrollbar {
      width: 2px;
    }
  }
  .main_nav__inner {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .hasChildren .children_nav {
    display: none;
    @include prefix((
        flex-direction: column,
        align-items: stretch,
    ), webkit);
    .children_item {
      display: block;
      width: 100%;
    }
  }
}

.main_nav_item__innerBody {
  display: grid;
  grid-template-columns: 100%;
  gap: rem(4px);
}

.main_linkInner {
  display: flex;
  align-items: center;
  padding: rem(12px);
  border-radius: rem(10px);
  gap: rem(8px);
  line-height: 1.42;
  font-size: rem(13px);
  color: $dark;
  transition: background-color .2s ease-in-out, color .2s ease-in-out;

  &:hover {
    /* mouse, touch pad */
    @media (hover: hover) and (pointer: fine) {
      background-color: $border_color;
      color: $title-color;
      text-decoration: none;
    }
  }

  &.active {
    background-color: $border_color;
    color: $blue;

    @media (hover: hover) and (pointer: fine) {
      color: $blue;
    }
  }
}

.main_linkInner__icon {
  width: rem(16px);
  height: rem(16px);
  font-size: rem(16px);
  display: block;
}

.main_nav_item__innerBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  z-index: 1;
}

.main_nav_item.disabled-a-in {
    opacity: .50;
    filter: brightness(90%);
}
.main_nav_item.disabled-a-in a {
    pointer-events: none;
    cursor: not-allowed;
    text-decoration: none;

}
.main_nav_item .main_link,
.children_nav .children_item > .children_link {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: $dark;
  background-color: $white;
  width: 100%;
  transition: background-color .3s ease-in-out 0s, color .3s ease-in-out 0s ;
  padding: rem(12px) rem(8px);
}

.main_nav_item .main_link.active {
  color: $blue;
}
.main_nav_item .main_link > div,
.children_item .children_link div {
  border-radius: rem(10px);
  padding: rem(12px);
  text-align: center;
  cursor: pointer;
  transition: background-color .3s ease-in-out;

  span:not(.title) {
    font-size: rem(24);
    display: block;

    svg {
      display: block;
    }

  }
  span.title {
    font-size: rem(13);
    line-height: 1.42;
  }
}

.main_nav_item .main_link {
  &.disabled {
    cursor: not-allowed;

    > div {
      opacity: .2;
      cursor: not-allowed;
    }
  }
}

.main_nav_item > a:hover,
.main_nav_item > button:hover
 {
  &> div {
    background-color: $border_color;
  }
  color: $title-color;
}

.main_nav_item > a.active,
.main_nav_item > button.active {
  &> div {
    background-color: $border_color;
  }
}

.children_item {
  .children_link:hover, .children_link.active {
    color: $blue;
  }
}
aside.aside .hasChildren.active {
  .children_nav {
    @include prefix_val_inn((
        display: flex,
    ), webkit);
  }
  .children_item .children_link {
    background-color: #F7F7F7;
  }
}

@media screen and (min-width: 500px) {
  .auth__wrapper {
    padding-top: rem(79);
  }
}
@media screen and (max-width: 500px) {
  .reg_auth_row {
    padding: rem(5) rem(20);
    .logo_block {
      max-width: 115px;
    }
  }
  .page_connect_head .logo_head .connect_exit {
    top: rem(-25);
    font-size: rem(16);
  }
  .page_connect_head .connection_title_section {
    h1 {
      font-size: rem(26);
      span {
        display: block;
      }
    }
    .subtitle {
      font-size: rem(16);
    }
  }
}

.auth__wrapper .form__outer {
  padding-left: 20px;
  padding-right: 20px;
}
.auth__wrapper .auth__block {
  padding-left: 20px;
  padding-right: 20px;
}
.form__outer .feedback_block {
  padding: rem(32) 0.9375rem 0;
}

@media (max-width: 355px) {
  .auth__wrapper .service__links {
    flex-direction: column;
    -webkit-flex-direction: column;
    div ~ div {
      margin-top: rem(14);
    }
  }
}

@media (min-width: 421px) {
  .user_auth_dropdown .user_block .user_thumb {
    background: $border_color;
  }
}

@media (min-width: 769px) {
  .auth_row {
    padding: 0 rem(20px);
  }
}
.user_account_current {
    font-size: 0.6rem;
    font-weight: 300;
    line-height: 10px;
}
