@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Medium.woff2') format('woff2'),
    url('../fonts/OpenSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-SemiBold.woff2') format('woff2'),
    url('../fonts/OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Regular.woff2') format('woff2'),
    url('../fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Bold.woff2') format('woff2'),
    url('../fonts/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face{
    font-family:'text-security-disc';
    font-display: block;
    src:url('../fonts/text-security-disc-compat.eot'),
    url('../fonts/text-security-disc-compat.eot?#iefix') format('embedded-opentype'),
    url('../fonts/text-security-disc.woff2') format('woff2'),
    url('../fonts/text-security-disc-compat.ttf') format('truetype')
}


