@mixin prefix($declarations, $prefixes: ()) {
  @each $property, $value in $declarations {
    @each $prefix in $prefixes {
      #{'-' + $prefix + '-' + $property}: $value;
    }
    #{$property}: $value;
  }
}
@mixin prefix_kit($declarations, $prefixes: ()) {
  @each $property, $value in $declarations {
    @each $prefix in $prefixes {
      #{'-' + $prefix + '-' + $property}: $value;
    }
    //#{$property}: $value;
  }
}
@mixin prefix_val($declarations, $prefixes: ()) {
  @each $property, $value in $declarations {
    @each $prefix in $prefixes {
      #{$property}: $prefix+$value;
    }
    #{$property}: $value;
  }
}
@mixin prefix_val_inn($declarations, $prefixes: ()) {
  @each $property, $value in $declarations {
    @each $prefix in $prefixes {
      #{$property}: - $prefix - $value;
    }
    #{$property}: $value;
  }
}