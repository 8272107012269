$base-font-size: 16px;

$white: #fff;
$white_06: rgba($white, .6);


$black: #000000;
$black_01: rgba($black, .1);
$black_02: rgba($black, .2);
$black_04: rgba($black, .4);

$title-color: #232323;
$border_color: #F0F0F0;

$blue: #4154f1;
$blue_hover: #3549ee;
$dark: #555555;
$dark_grey: #999999;
$light_grey: #F9F9F9;
$dark_04: rgba($dark, .4);
$dark_02: rgba($dark, .2);
$dark_01: rgba($dark, .1);

$primary-300: #54A631;
$primary-200: #6EBF4B;
$primary-100: #F0F7E9;
$primary-50: #F0F9ED;


$grey-200: #E4E5E7;
$grey-100: #F6F6F6;
$grey-300: #F7F7F7;
$grey-400: #B9BEC6;

$secondary-300: #FF784F;
$danger: #EE3131;
$success: #17B787;
$warning: #e9c02e;
$wish: #F87650;

$peach-800: #93370D;
$peach-300: #EBAA5B;
$peach-200: #FEDF89;
$peach-100: #FEF9EC;

$error-300: #EB5757;
$error-100: #FDF1F1;
$info: #EE4949;

$accent: #52B4FB;
$inputPlaceholder: #BDBDBD;
